function setCssConfig(uiConfig, document: Document) {
  const styles = uiConfig.styles;
  const images = uiConfig.images;

  document.documentElement.classList.add('v2-ticketpicker');

  document.documentElement.style.setProperty('--BANNER__reverse-text', styles['BANNER__reverse-text']); // boolean to control mixins
  document.documentElement.style.setProperty('--BANNER__title__font', styles['BANNER__title__font']); // string to control mixins
  document.documentElement.style.setProperty('--BANNER__background-color', styles['BANNER__background-color']); // string to control mixins
  document.documentElement.style.setProperty('--BUTTON__background-color', styles['BUTTON__background-color']);
  document.documentElement.style.setProperty('--CONTENT__background-color', styles['CONTENT__background-color']);
  document.documentElement.style.setProperty('--CONTENT__heading-color', styles['CONTENT__heading-color']);
  document.documentElement.style.setProperty('--CONTENT__reverse-text', styles['CONTENT__reverse-text']);
  document.documentElement.style.setProperty('--CONTENT__text-color', styles['CONTENT__text-color']);
  document.documentElement.style.setProperty('--FULLSCREEN', styles['FULLSCREEN']);
  document.documentElement.style.setProperty('--GLOBAL__COLOR__ACCENT', styles['GLOBAL__COLOR__ACCENT']);
  document.documentElement.style.setProperty('--GLOBAL__COLOR__PRIMARY', styles['GLOBAL__COLOR__PRIMARY']);
  document.documentElement.style.setProperty('--GLOBAL__PARTNER__SLUG', styles['GLOBAL__PARTNER__SLUG']);
  document.documentElement.style.setProperty('--GLOBAL__PARTNER__URL', styles['GLOBAL__PARTNER__URL']);
  document.documentElement.style.setProperty('--LINK__color', styles['LINK__color']);
  document.documentElement.style.setProperty('--PAGE__background-color', styles['PAGE__background-color']);

  if (images['PAGE__background-image']) {
    images['PAGE__background-image'] = `url('${images['PAGE__background-image']}')`;
  } else {
    images['PAGE__background-image'] = 'none';
  }

  if (images['checkoutHeader']) {
    images['CHECKOUT__HEADER__background-image'] = `url('${images['checkoutHeader']}')`;
  } else {
    images['CHECKOUT__HEADER__background-image'] = 'none';
  }

  if (images['header']) {
    images['PAGE__HEADER__image'] = `url('${images['header']}')`;
  } else {
    images['PAGE__HEADER__image'] = 'none';
  }
  document.documentElement.style.setProperty('--PAGE__background-image', images['PAGE__background-image']);
  document.documentElement.style.setProperty('--PAGE__background-image-dim', images['PAGE__background-image-dim']);
  document.documentElement.style.setProperty('--CHECKOUT__HEADER__background-image', images['CHECKOUT__HEADER__background-image']);
  document.documentElement.style.setProperty('--PAGE__HEADER__image', images['PAGE__HEADER__image']);

  // background-image: url('#{$GLOBAL__PARTNER__URL}/assets/partners/#{$GLOBAL__PARTNER__SLUG}/images/logo-dark.png');
  const partnerLogoLight = `url("${styles['GLOBAL__PARTNER__URL']}/assets/partners/${styles['GLOBAL__PARTNER__SLUG']}/images/logo-light.png")`;
  const partnerLogoDark = `url("${styles['GLOBAL__PARTNER__URL']}/assets/partners/${styles['GLOBAL__PARTNER__SLUG']}/images/logo-dark.png")`;

  document.documentElement.style.setProperty('--GLOBAL__PARTNER__LOGO__LIGHT', partnerLogoLight);
  document.documentElement.style.setProperty('--GLOBAL__PARTNER__LOGO__DARK', partnerLogoDark);

  if (styles['PARTNER_FONT_FAMILY']) {
    document.documentElement.style.setProperty('--PARTNER_FONT_FAMILY', styles['PARTNER_FONT_FAMILY']);
  }

  if (styles['PARTNER_FONT_CSS_URL']) {
    const fontImport = document.createElement('link');
    fontImport.rel = 'stylesheet';
    fontImport.href = styles['PARTNER_FONT_CSS_URL'];
    document.head.appendChild(fontImport);
  }

  // Add the class to the element
  if (styles['FULLSCREEN']) {
    document.documentElement.classList.add('banner--fullscreen');
  }

  if (styles['BANNER__title__font'] == 'red-hat' || styles['BANNER__title__font'] == 'metropolis') {
    document.documentElement.style.setProperty('--LISTING__TITLE__font-family', '"Red Hat Display", sans-serif');
  } else if (styles['BANNER__title__font'] == 'bangers') {
    document.documentElement.style.setProperty('--LISTING__TITLE__font-family', '"Bangers", sans-serif');
    document.documentElement.style.setProperty('--LISTING__TITLE__font-weight', 'normal');
    document.head.insertAdjacentHTML(
      'beforeend',
      "<style>@import url('https://fonts.googleapis.com/css?family=Bangers');</style>",
    );
  } else if (styles['BANNER__title__font'] == 'lobster') {
    document.documentElement.style.setProperty('--LISTING__TITLE__font-family', '"Lobster", sans-serif');
    document.head.insertAdjacentHTML(
      'beforeend',
      "<style>@import url('https://fonts.googleapis.com/css?family=Lobster');</style>",
    );
  } else if (styles['BANNER__title__font'] == 'roboto') {
    document.documentElement.style.setProperty('--LISTING__TITLE__font-family', '"Roboto", sans-serif');
    document.head.insertAdjacentHTML(
      'beforeend',
      "<style>@import url('https://fonts.googleapis.com/css?family=Roboto');</style>",
    );
  } else if (styles['BANNER__title__font'] == 'playfair') {
    document.documentElement.style.setProperty('--LISTING__TITLE__font-family', '"Playfair Display", sans-serif');
    document.head.insertAdjacentHTML(
      'beforeend',
      "<style>@import url('https://fonts.googleapis.com/css?family=Playfair+Display');</style>",
    );
  } else if (styles['BANNER__title__font'] == 'alfa') {
    document.documentElement.style.setProperty('--LISTING__TITLE__font-family', '"Alfa Slab One", sans-serif');
    document.documentElement.style.setProperty('--LISTING__TITLE__font-weight', 'normal');
    document.documentElement.style.setProperty('--LISTING__TITLE__text-transform', 'uppercase');
    document.head.insertAdjacentHTML(
      'beforeend',
      "<style>@import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One');</style>",
    );
  } else if (styles['BANNER__title__font'] == 'abel') {
    document.documentElement.style.setProperty('--LISTING__TITLE__font-family', '"Abel", sans-serif');
    document.documentElement.style.setProperty('--LISTING__TITLE__font-weight', 'normal');
    document.documentElement.style.setProperty('--LISTING__TITLE__text-transform', 'uppercase');
    document.head.insertAdjacentHTML(
      'beforeend',
      "<style>@import url('https://fonts.googleapis.com/css?family=Abel');</style>",
    );
  } else if (styles['BANNER__title__font'] == 'shrikhand') {
    document.documentElement.style.setProperty('--LISTING__TITLE__font-family', '"Shrikhand", sans-serif');
    document.documentElement.style.setProperty('--LISTING__TITLE__font-weight', 'normal');
    document.head.insertAdjacentHTML(
      'beforeend',
      "<style>@import url('https://fonts.googleapis.com/css?family=Shrikhand');</style>",
    );
  }
}

window.setCssConfig = setCssConfig;
